/******* Angular Resourse *******/
import { Inject, Injectable } from '@angular/core';
import { BaseApi } from '@api/base/base.service';
import { HttpClient } from '@angular/common/http';
import { Filter, PciRequestBody } from '@core/types';

@Injectable()
export class GeoPci extends BaseApi {

    constructor(@Inject(HttpClient) protected http: HttpClient) {
        super(http);
    }

    /**
     * GET /geo-pci - get pci count
     * 
     * @param {Filter} filter
     */
    public getGeoPci(filter: Filter) {
        return this.find("geo-pci", filter);
    }

    /**
     * GET /geo-pci/count - get pci count
     * 
     * @param where
     */
    public getGeoPciCount(where) {
        return this.count("geo-pci", where);
    }

    /**
     * POST /geo-pci - create new geo pci
     * 
     * @param {PciRequestBody} data
     */
    public createGeoPci(data: PciRequestBody) {

        let method = "POST",
            url = "geo-pci",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * PATCH /geo-pci/{id} - update geo pci
     * 
     * @param {string} id
     * @param {PciRequestBody} data
     */
    public updateGeoPci(id: string, data: PciRequestBody) {

        let method = "PATCH",
            url = "geo-pci/:id",
            routeParams = { id },
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * DELETE /geo-pci/{id} - delete geo pci
     * 
     * @param {string} id
     */
    public deleteGeoPci(id: string) {

        let method = "DELETE",
            url = "geo-pci/:id",
            routeParams = { id },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * DELETE /geo-pci - delete all geo pci
     */
    public deleteAllGeoPci() {

        let method = "DELETE",
            url = "geo-pci",
            routeParams = {},
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }
}