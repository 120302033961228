/******* Angular Resourse *******/
import { ElementRef, Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

/******* Const Resourse *******/
import { SIGNAL_CAP } from '@core/constant';
import { DeviceAlertType, DistanceUnit, SignalDisplayObject, TimeZone, Coordinate, NotificationType, ConfigStatusObject, RolePermission, FileTransferingProgress, FileStage, Signal } from '@core/types';

/******* Plug-In Resourse *******/
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { DateTime } from "luxon";
import { HttpEvent, HttpEventType } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {

    /**
     * Constructor
     *   
     * @param {ToastrService} _toastrService 
     */
    constructor(private _toastrService: ToastrService) { }

    //  Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the sweet alert template
     * 
     * @returns {typeof Swal}
     */
    get swalMixin(): typeof Swal {
        return Swal.mixin({
            showCancelButton: true,
            showClass: { popup: 'animate__animated animate__bounceIn' },
            customClass: {
                container: 'max-z-index',
                title: 'mt-2 mb-2',
                content: 'loading-display-none',
                confirmButton: 'btn btn-primary loading-border-transparent',
                cancelButton: 'btn btn-secondary loading-display-none',
                input: "form-control"
            },
            allowOutsideClick: true,
            allowEscapeKey: true
        });
    }

    // Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Radians to Degree
     * @param radians 
     * @returns 
     */
    private radiansToDegrees(radians) {
        const degree = radians % (2 * Math.PI);
        return degree * 180 / Math.PI;
    }

    /**
     * Get Top Offset of element
     * 
     * @param {HTMLElement} controlEl
     */
    private getTopOffset(controlEl: HTMLElement): number {
        const labelOffset = 50;
        return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
    }

    // Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the signal display object
     * 
     * @param {string} signalTag 
     * @param {number} strength  
     * @param {any} thresholds 
     * @returns {SignalDisplayObject}
     */
    public createSignalDisplayObject(signalTag: string, strength: number, thresholds: any = null): SignalDisplayObject {
        if (!strength) {
            return {
                signalTag,
                color: '#b1b1b1',
                level: 0,
                strength: "--",
                comment: "not available"
            };
        }

        let tag = signalTag.substring(0, 4);
        let level_count = tag == "RSRQ" ? 1 : 0;
        thresholds = thresholds || SIGNAL_CAP[signalTag].thresholds;
        for (let range of thresholds) {
            level_count++;
            if (strength >= range.larger && strength <= range.less) {
                return {
                    signalTag,
                    color: range.color,
                    level: level_count,
                    strength: Math.round(strength).toString(),
                    comment: range.comment
                };
            }
        }

        return {
            signalTag,
            color: '#b1b1b1',
            level: 0,
            strength: "--",
            comment: "not available"
        };
    }

    /**
     * Automatically Download the File
     * 
     * @param data 
     * @param {string} dataType 
     * @param {string} fileName 
     */
    public downloadFile(data: any, dataType: string, fileName: string): void {
        // Create trigger
        var hiddenElement = document.createElement('a');

        // Set trigger info
        let blob = new Blob([data], { type: dataType });
        hiddenElement.href = URL.createObjectURL(blob);
        hiddenElement.target = "_blank";
        hiddenElement.download = fileName;

        // Set the Mouse Click event
        let Event_Click = new MouseEvent("click");
        hiddenElement.dispatchEvent(Event_Click); // Fire event
    }

    /**
     * Calculate the Distance between two coordinate
     * 
     * @param {Coordinate} locationStart
     * @param {Coordinate} locationEnd
     * @param {DistanceUnit} unit
     * @returns {number}
     */
    public calculateDistance(locationStart: Coordinate, locationEnd: Coordinate, unit: DistanceUnit = DistanceUnit.MILES): number {

        const rad = Math.PI / 180;

        let radlat1 = locationStart.Latitude * rad;
        let radlat2 = locationEnd.Latitude * rad;
        let radtheta = (locationStart.Longitude - locationEnd.Longitude) * rad;

        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) dist = 1;

        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit === DistanceUnit.KILOMETERS) { dist = dist * 1.609344 }
        if (unit === DistanceUnit.NAUTICAL_MILES) { dist = dist * 0.8684 }

        return Math.round(dist * 100) / 100;
    }

    /**
     * Calculate the Azimuth between two coordinate
     * 
     * @param {Coordinate} locationStart
     * @param {Coordinate} locationEnd
     * @returns {number}
     */
    public calculateAzimuth(locationStart: Coordinate, locationEnd: Coordinate) {

        let rad = Math.PI / 180,
            lat1 = locationStart.Latitude * rad,
            lat2 = locationEnd.Latitude * rad,
            lon1 = locationStart.Longitude * rad,
            lon2 = locationEnd.Longitude * rad;

        const a = Math.sin(lon2 - lon1) * Math.cos(lat2);
        const b = Math.cos(lat1) * Math.sin(lat2) -
            Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1);

        const Azimuth = Math.round(this.radiansToDegrees(Math.atan2(a, b)) * 100) / 100;
        return Azimuth > 0 ? Azimuth : 360.0 + Azimuth;
    }

    /**
     * Create an array filling with assign value
     * 
     * @param {number} length
     * @param {number | string} value
     * @returns {Array<number | string>}
     */
    public initialArray(length: number, value: number | string): Array<number | string> {
        return new Array(length).fill(value);
    }

    /**
     * Scroll to invalid element and focus in form
     * 
     * @param {ElementRef} element
     */
    public scrollToInvalid(element: ElementRef): void {
        const firstInvalidControl: HTMLElement = element.nativeElement.querySelector(
            "form input.ng-invalid, form select.ng-invalid"
        );

        if (firstInvalidControl) {
            firstInvalidControl.focus();
            window.scroll({
                top: this.getTopOffset(firstInvalidControl),
                left: 0,
                behavior: "smooth"
            });
        }
    }

    /**
     * Scroll to the top of the element
     * 
     * @param {Element} element 
     */
    public scrollToTop(element: Element): void {
        (function smoothscroll() {
            const currentScroll = element.scrollTop;
            if (currentScroll > 0.00001) {
                window.requestAnimationFrame(smoothscroll);
                element.scrollTop = currentScroll - currentScroll / 4;
            } else {
                element.scrollTop = 0;
                return;
            }
        })();
    }

    /**
     * Convert number to two digit string
     * 
     * @param {number} num
     * @returns {string}
     */
    public toTwoDigit(num: number): string {
        return num.toString().padStart(2, '0');
    }

    /**
     * Trigger global notification chip alert
     * 
     * @param {NotificationType} alertType 
     * @param {string} title 
     * @param {string} content 
     */
    public alert(alertType: NotificationType, title: string, content: string): void {
        this._toastrService[alertType](
            content,
            title,
            {
                timeOut: 3000,
                toastClass: 'toast ngx-toastr max-z-index',
                closeButton: false
            }
        )
    }

    /**
     * Check the string is JSON string
     * 
     * @param {string} testString 
     * @returns {object}
     */
    public isJsonString(testString: string): object {
        try {
            const JSON_OBJECT = JSON.parse(testString);
            return JSON_OBJECT;
        } catch (e) {
            return {};
        }
    }

    /**
     * Convert to Config status string
     * 
     * @param {ConfigStatusObject} obj 
     * @returns {string}
     */
    public toConfigStatus(obj: ConfigStatusObject): string {
        if (obj.isProgress) return "clock";
        else if (obj.pending && Object.keys(obj.pending).length > 0) return "clock";
        else if (!obj.isProgress && obj.isSynced) return "synced";
        else if (!obj.isProgress && !obj.isSynced) return "not synced";
    }

    /**
     * Convert Yes/No to true/false
     * 
     * @param {string} input 
     * @returns {boolean}
     */
    public stringToBooleanConverter(input: string): boolean {
        return input ? input.toUpperCase() == "YES" : false;
    }

    /**
     * Convert 1/0 to true/false
     * 
     * @param {string} input 
     * @returns {boolean} 
     */
    public numberToBooleanConverter(input: string): boolean {
        return input ? input === '1' : false;
    }

    /**
     * Convert boolean back to 1/0 or Yes/No 
     * 
     * @param {boolean} input 
     * @param {'string' | 'number'} type
     * @returns {string}
     */
    public booleanReverseConverter(input: boolean, type: 'string' | 'number'): string {
        switch (type) {
            case 'string': return input ? "Yes" : "No";
            case 'number': return input ? "1" : "0";
        };
    }

    /**
     * Set enable/disable of controls in reactive form
     * 
     * @param {Array<FormControl | FormGroup | FormArray>} controls 
     * @param {boolean} disabled 
     */
    public disableContent(controls: Array<UntypedFormControl | UntypedFormGroup | UntypedFormArray>, disabled: boolean): void {
        controls.forEach((control) => {
            if (disabled) control.disable();
            else control.enable();
        });
    }

    /**
     * Patch values with mark dirty
     * 
     * @param {FormGroup} group 
     * @param {any} patchData 
     * @param {boolean} markAsDirty 
     */
    public patchValues(group: UntypedFormGroup, patchData: any, markAsDirty: boolean): void {
        group.patchValue(patchData);
        const control = group.controls;
        if (markAsDirty) for (let key in patchData) control[key].markAsDirty();
    }

    /**
     * Translate GPS Coordinate to readable coordinate
     *  
     * @param {string} latitude 
     * @param {string} longitude 
     * @returns {Array<number>}
     */
    public translateGpsCoordanate(latitude: string, longitude: string): Array<number> {
        let gpsFormatTester: RegExp = /^[NEWS][0-9]+[.][0-9]+$/;
        if (gpsFormatTester.test(latitude) && gpsFormatTester.test(longitude)) {
            let Lat = (latitude[0] == "N" ? 1 : -1) * (parseFloat(latitude.substring(1, 3)) + (parseFloat(latitude.substring(3)) / 60));
            let Long = (longitude[0] == "E" ? 1 : -1) * (parseFloat(longitude.substring(1, 4)) + (parseFloat(longitude.substring(4)) / 60));
            return [Math.round(Lat * 1000000) / 1000000, Math.round(Long * 1000000) / 1000000];
        }
        else return [32.99955578182, -96.6617263337];
    }

    /**
     * Convert time in sec to readable string
     * 
     * @param {any} dataSource 
     * @param {string} key
     * @returns {string}
     */
    public toTimeString(dataSource: any, key: string = "SYSUPTIME"): string {
        // check status
        if (typeof dataSource.connected == 'undefined') return "";
        if (!dataSource.connected || !dataSource[key]) return "-- Hr " + "-- Min";

        let Seconds = dataSource[key];

        //Deal with Time
        let UPTIME = parseInt(Seconds);
        let Second = 0;
        let Min = 0;
        let Hour = 0;
        let Days = 0;

        Second = UPTIME % 60;
        Min = Math.floor(UPTIME / 60);
        if (Min >= 60) {
            Hour = Math.floor(Min / 60);
            Min = Min % 60;
        }

        if (Hour >= 24) {
            Days = Math.floor(Hour / 24);
            Hour = Hour % 24;

            return Days + " D " + Hour + " Hr " + Min + " Min";
        }
        else {
            return Hour + " Hr " + Min + " Min";
        }
    }

    /**
     * Create a random string with assigned length
     * 
     * @param {number} length 
     * @returns {string}
     */
    public getRandomString(length: number): string {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    /**
     * Get Date with mutation days
     * 
     * @param {number} days 
     * @returns {Date}
     */
    public getDateWithMutation(days: number): Date {
        let d = new Date();
        d.setDate(d.getDate() + days);
        return d;
    }

    /**
     * Parsing permisson item into group
     * 
     * @param {string} permisson
     * @returns {string}
     */
    public parsingPermissionGroup(permission: string): string {

        switch (permission) {
            case RolePermission.COMMAND_REBOOT:
            case RolePermission.COMMAND_RESTORE:
            case RolePermission.COMMAND_UPGRADE_FW:
            case RolePermission.COMMAND_UPGRADE_CFG:
            case RolePermission.COMMAND_BACKUP_CFG:
            case RolePermission.COMMAND_DIAGNOSTIC:
            case RolePermission.COMMAND_CBSD:
                return "Command";
            case RolePermission.DEVICE_CFG:
            case RolePermission.DEVICE_ANALYSIS:
            case RolePermission.DEVICE_EDIT:
            case RolePermission.DEVICE_REMOTE_ACCESS:
            case RolePermission.DEVICE_DELETE:
                return "Device";
            case RolePermission.VIEW_ANALYTICS:
            case RolePermission.VIEW_CELL_ANALYSIS:
            case RolePermission.VIEW_SCHEDULER:
            case RolePermission.VIEW_PROFILE_TEMPLATE:
            case RolePermission.VIEW_FILE_MANAGEMENT:
            case RolePermission.VIEW_REPORT:
            case RolePermission.VIEW_MAP:
                return "View";
            case RolePermission.FEATURES_ALERT:
            case RolePermission.FEATURES_REST_API:
            case RolePermission.FEATURES_WEBHOOK:
                return "Feature";
            case RolePermission.CONFIG_INTERFACE:
            case RolePermission.CONFIG_DUAL_WAN:
            case RolePermission.CONFIG_WIFI:
            case RolePermission.CONFIG_ACCESS_MANAGEMENT:
            case RolePermission.CONFIG_MAINTENANCE:
            case RolePermission.CONFIG_ADVANCED_SETUP:
                return "Config";
            default:
                return "";
        }
    }

    /**
     * Get all dirty values recursively 
     * 
     * @param {FormGroup} form 
     * @returns {any}
     */
    public getDirtyValues(form: UntypedFormGroup): any {
        let dirtyValues = {};
        Object.keys(form.controls)
            .forEach(key => {
                const currentControl = form.controls[key];
                if (currentControl.dirty && currentControl.status != 'DISABLED') {
                    if (currentControl.hasOwnProperty('controls'))
                        dirtyValues[key] = this.getDirtyValues(currentControl as UntypedFormGroup);
                    else
                        dirtyValues[key] = currentControl.value;
                }
            });
        return dirtyValues;
    }

    /**
     * Valid BEC MAC address
     * 
     * @param {string} testInput 
     * @returns {string}
     */
    public validateBECMacAddress(testInput: string): string {
        if (!testInput) return "MAC can not be empty.";
        let macFormat: RegExp = /(^0004ED[0-9A-F]{6}$|^600347[0-9A-F]{6}$)/;
        const upperCaseInput = testInput.toUpperCase();
        const format = macFormat.test(upperCaseInput);
        return format ? "" : "Incorrect MAC Format."
    }

    /**
     * Parse IPv4 into array of location or null
     * 
     * @param {string} testString 
     * @returns {Array<string> | null}
     */
    public parseIpv4(testString: string): Array<string> | null {
        if (!testString.match(/^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/)) return null;
        let [num1, num2, num3, num4] = testString.split('.');
        if (parseInt(num1) > 255 || parseInt(num2) > 255 || parseInt(num3) > 255 || parseInt(num4) > 255) return null;
        return [num1, num2, num3, num4];
    }

    /**
     * Resolve license bucket count into bucket name
     * 
     * @param {number} licenseCount 
     * @returns {string}
     */
    public toLicenseBucketName(licenseCount: number): string {
        if (!licenseCount) return "";
        const license = licenseCount < 1000 ? licenseCount.toString() : ((licenseCount / 1000) + "K");
        return `ISP-MO-${license}LP`;
    }

    /**
     * Convert to MB
     * 
     * @param {number} data 
     * @returns {string}
     */
    public convertToMB(data): string {
        let byteData = Math.floor(data);
        return (Math.floor((byteData / 1048576) * 100) * 0.01).toFixed(2);
    }

    /**
     * Render alert description
     * 
     * @param {DeviceAlertType} alertType 
     * @param {string | number} value 
     * @param {'Alert' | 'Caution'} alertLevel 
     * @returns {string}
     */
    public alertDescription(alertType: DeviceAlertType, value: string | number, alertLevel: "Alert" | "Caution"): string {
        switch (alertType) {
            case DeviceAlertType.RSRP:
            case DeviceAlertType.RSRQ:
            case DeviceAlertType.SINR:
            case DeviceAlertType.RSSI:
            case DeviceAlertType.NR_RSRP:
            case DeviceAlertType.NR_RSRQ:
            case DeviceAlertType.NR_SINR:
                return `${value} dBm have been lower than the ${alertLevel.toLowerCase()} value.`;
            default:
                return value.toString();
        }
    }

    /**
     * Convert date string to ISO String
     * 
     * @param {string} dateString 
     * @param {TimeZone} timezone 
     * @returns {string}
     */
    public convertToISOString(dateString: string, timezone: TimeZone): string {
        const timeZoneOffset = DateTime.now().setZone(timezone).offset;
        const offset = timeZoneOffset * 60 * 1000;
        const dateObject = new Date(Date.parse(dateString) - offset);
        return `${dateObject.getFullYear()}-${this.toTwoDigit(dateObject.getMonth() + 1)}-${this.toTwoDigit(dateObject.getDate())}` +
            `T${this.toTwoDigit(dateObject.getHours())}:${this.toTwoDigit(dateObject.getMinutes())}:00.000Z`;
    }

    /**
     * Request full screen for element
     * 
     * @param element 
     */
    public requestElementFullScreen(element: HTMLElement | any): void {
        if (element.requestFullscreen) element.requestFullscreen();
        else if (element.webkitRequestFullscreen) element.webkitRequestFullscreen(); /* Safari */
        else if (element.msRequestFullscreen) element.msRequestFullscreen(); /* IE11 */
    }

    /**
     * Exit full screen for element
     * 
     * @param element 
     */
    public exitElementFullScreen(element: HTMLElement | any): void {
        if (element.exitFullscreen) element.exitFullscreen();
        else if (element.webkitExitFullscreen) element.webkitExitFullscreen(); /* Safari */
        else if (element.msExitFullscreen) element.msExitFullscreen(); /* IE11 */
    }

    /**
     * convert message to progress
     * 
     * @param {HttpEvent<any>} event 
     * @param {File} file 
     * @returns {FileTransferingProgress}
     */
    public getEventMessage(event: HttpEvent<any>): FileTransferingProgress | any {
        switch (event.type) {
            case HttpEventType.Sent:
                return { progress: 0, state: FileStage.IN_PROGRESS };
            case HttpEventType.UploadProgress:
                if (event.loaded < event.total) {
                    let percent = event.total ? Math.round(100 * event.loaded / event.total) : 0;
                    return { progress: percent, state: FileStage.IN_PROGRESS };
                }
                else if (event.loaded === event.total) {
                    return { progress: 100, state: FileStage.UPDATE };
                }
            case HttpEventType.Response: return event['body'];
            default: return "";
        }
    }

    /**
     * calculater GnbId and Sector Id from NCI 
     * 
     * @param {string} nciInHex 
     * @param {number} gNbIdLength
     * @returns {Array<string>}
     */
    public calculateGnbId(nciInHex: string, gNbIdLength: number): Array<string> {
        let binary = parseInt(nciInHex, 16).toString(2);
        let prePendZero = Array(36 - binary.length).fill(0).join('');
        let finalString = `${prePendZero}${binary}`;
        return [
            parseInt(finalString.substring(0, gNbIdLength), 2).toString(10),
            parseInt(finalString.substring(gNbIdLength), 2).toString(10)
        ];
    }
}