import { PermissionItem } from "@core/types";

export const PERMISSION: Array<PermissionItem> = [
    {
        id: "Command",
        name: "Command",
        children: [
            { id: "CommandReboot", name: "Reboot" },
            { id: "CommandRestore", name: "Restore" },
            { id: "CommandUpgradeFw", name: "Upgrade FW" },
            { id: "CommandUpgradeCfg", name: "Upgrade CFG" },
            { id: "CommandBackupCfg", name: "Backup CFG" },
            { id: "CommandDiagnostic", name: "Diagnostic" },
            { id: "CommandCBSD", name: "CBSD" }
        ]
    },
    {
        id: "Device",
        name: "Device",
        children: [
            { id: "DeviceCfg", name: "CFG" },
            { id: "DeviceAnalysis", name: "Analysis" },
            { id: "DeviceEditInfo", name: "Edit Info" },
            { id: "DeviceRemoteAccess", name: "Remote Access" },
            { id: "DeleteDevice", name: "Delete Device" }
        ]
    },
    {
        id: "View",
        name: "View",
        children: [
            { id: "ViewAnalytics", name: "Analytics" },
            { id: "ViewCellAnalysis", name: "Cell Analysis" },
            { id: "ViewScheduler", name: "Scheduler" },
            { id: "ViewProfileTemplate", name: "Profile Template" },
            { id: "ViewFileManagement", name: "Files Management" },
            { id: "ViewReport", name: "Report" },
            { id: "ViewMap", name: "Map" }
        ]
    },
    {
        id: "Feature",
        name: "Feature",
        children: [
            { id: "FeatureAlert", name: "Alert" },
            { id: "FeatureRestApi", name: "API" },
            { id: "FeatureWebhook", name: "Webhooks" },
        ]
    },
    {
        id: "Config",
        name: "Configuration",
        children: [
            { id: "ConfigInterface", name: "Interface" },
            { id: "ConfigDualWan", name: "Dual-Wan" },
            { id: "ConfigWifi", name: "Wi-Fi" },
            { id: "ConfigAccessManagement", name: "Access Management" },
            { id: "ConfigMaintenance", name: "Maintenance" },
            { id: "ConfigAdvancedSetup", name: "Advanced Setup" }
        ]
    }
];