export interface PciRequestBody {
    id?: string;
    PCI: string;
    ECI? : string | number;
    NCI? : string | number;
    name: string;
    address?: string;
    latitude?: number;
    longitude?: number;
    tenantId?: string;
}