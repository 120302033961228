import { ProtocolBindingProtocol, RadioActive, SelectOption, SupportDeviceModel } from "@core/types";

export const INITIAL_ACCESS_CONTROL_VALUE = {
    Activate: 'No',
    ScrIPAddrBegin: '0.0.0.0',
    ScrIPAddrEnd: '0.0.0.0',
    Application: 'Web',
    Interface: 'WAN'
};

export const INITIAL_UD_APP_VALUE = {
    userapp: 'No',
    uname: '',
    uproto: 'UDPTCP',
    uport: ''
};

export const INITIAL_IP_MAC_FILTER_VALUE = {
    Active: 'No',
    Interface: 'PVC11',
    EwanIndex: '0',
    Direction: 'Both',
    RuleType: 'IP',
    SrcIPAddr: '0.0.0.0',
    SrcIPMask: '0.0.0.0',
    SrcIPv6Addr: '0:0:0:0:0:0:0:0',
    SrcPrefix: '32',
    SrcPort: 0,
    DesIPAddr: '0.0.0.0',
    DesIPMask: '0.0.0.0',
    DesIPv6Addr: '0:0:0:0:0:0:0:0',
    DesPrefix: '32',
    DesPort: 0,
    DSCP: 64,
    Protocol: 'ANY',
    MacAddr: '',
    timeschid: '99'
};

export const INITIAL_URL_FILTER_VALUE = {
    Activate: '0',
    URL: '',
    timeschid: '99'
};

export const INITIAL_DOMAIN_FILTER_VALUE = {
    Activate: '0',
    DOMAIN: ''
};

export const INITIAL_USER_ACCOUNT_VALUE = {
    username: '',
    web_passwd: '',
    web_passwd_confirm: '',
    ftp_active: 'No',
    ftp_permission: '0',
    samba_active: 'No',
    samba_permission: '0',
    guestAccount: '0',
    vpn: '1',
    voip: '1',
    maintence: '1',
    access: '1',
    advanced: '1',
    _interface: '1',
    hotSpot: '1'
};

export const INITIAL_AUTO_REBOOT_SCHEDULE_VALUE = {
    Enable: 'No',
    StartTime: { hour: 0, minute: 0, second: 0 },
    Mon: false,
    Tue: false,
    Wed: false,
    Thu: false,
    Fri: false,
    Sat: false,
    Sun: false
};

export const OPENWRT_NETWORK_MODE: Array<SelectOption> = [
    { value: "1", name: "WCDMA only" },
    { value: "3", name: "LTE Only" },
    { value: "15", name: "5G only (SA mode)" },
    { value: "19", name: "LTE + 5G" },
    { value: "21", name: "Automatic" }
];

export const OPENWRT_PING_COUNT: Array<SelectOption> = [
    { value: "1", name: "1" },
    { value: "2", name: "2" },
    { value: "3", name: "3" },
    { value: "4", name: "4" },
    { value: "5", name: "5" },
    { value: "6", name: "6" },
    { value: "7", name: "7" },
    { value: "8", name: "8" },
    { value: "9", name: "9" },
    { value: "10", name: "10" }
];

export const OPENWRT_PING_INTERVAL: Array<SelectOption> = [
    { value: "5", name: "5 seconds" },
    { value: "10", name: "10 seconds" },
    { value: "20", name: "20 seconds" },
    { value: "30", name: "30 seconds" },
    { value: "60", name: "1 minute" },
    { value: "300", name: "5 minutes" },
    { value: "600", name: "10 minutes" },
    { value: "900", name: "15 minutes" },
    { value: "1800", name: "30 minutes" },
    { value: "3600", name: "1 hour" }
];

export const OPENWRT_PING_TIMEOUT: Array<SelectOption> = [
    { value: "1", name: "1 second" },
    { value: "2", name: "2 seconds" },
    { value: "3", name: "3 seconds" },
    { value: "4", name: "4 seconds" },
    { value: "5", name: "5 seconds" },
    { value: "6", name: "6 seconds" },
    { value: "7", name: "7 seconds" },
    { value: "8", name: "8 seconds" },
    { value: "9", name: "9 seconds" },
    { value: "10", name: "10 seconds" }
];

export const INITIAL_ROUTING_VALUE = {
    DST_IP: '0.0.0.0',
    Sub_mask: '0.0.0.0',
    Switcher: '0',
    Gateway: '0.0.0.0',
    Device: 'PVC11',
    metric: 1
};

export const INITIAL_IPV6_ROUTING_VALUE = {
    DST_IP: '::1',
    Prefix: '128',
    Switcher: '0',
    Gateway: '::',
    Device: 'PVC11',
    metric: 2048
};

export const INITIAL_OSPF_VALUE = {
    PVC: '11',
    AREA: ''
};

export const INITIAL_BGP_VALUE = {
    NEIP: '',
    NEASN: 1,
    NEIN: false,
    NHS: false,
    NESRI: false,
    NEMHOP: false
};

export const INITIAL_DMZ_VALUE = {
    ExpPort: 0,
    PROTOCOL: 'TCP',
    Descript: 0
};

export const INITIAL_VIRTUAL_SERVER_VALUE = {
    PROTOCOL: 'TCP',
    STARTPORT: 0,
    ENDPORT: 0,
    LOCALIP: '0.0.0.0',
    LOCAL_SPORT: 0,
    LOCAL_EPORT: 0,
    expact: '1',
    sipstart: '0.0.0.0',
    sipend: '0.0.0.0'
};

export const INIITIAL_STATIC_DNS = {
    IPAddr: "",
    DomainName: ""
};

export const INITIAL_PROTOCOL_BINDING_VALUE = {
    Active: RadioActive.YES,
    Interface: "1",
    SrcIPAddr: "0.0.0.0",
    SrcIPMask: "0.0.0.0",
    SrcPort: 0,
    DesIPAddr: "0.0.0.0",
    DesIPMask: "0.0.0.0",
    DesPort: 0,
    DSCP: 64,
    Protocol: ProtocolBindingProtocol.ANY,
    e1: 'No',
    e2: 'No',
    e3: 'No',
    w0: 'No',
    w1: 'No',
    w2: 'No',
    w3: 'No',
    r0: 'No',
    r1: 'No',
    r2: 'No',
    r3: 'No'
};

export const CBSD_FCC_ID_MAP: { [key: string]: string } = {
    [SupportDeviceModel._6900]: "QI3BEC-6900R21",
    [SupportDeviceModel._7000]: "QI3BEC-7000R28G",
    [SupportDeviceModel._8231]: "QI3BEC-8231",
    [SupportDeviceModel._8232]: "QI3BEC-8231",
    [SupportDeviceModel.MX_220]: "QI3BEC-MX220UTAB",
    [SupportDeviceModel.MX_220_UT]: "QI3BEC-MX220UTAB"
};

export const US_5G_CHANNEL_LIST: Array<number> = [
    36, 40, 44, 48, 52, 56, 60, 64, 100, 104, 108, 112, 116, 120, 124, 128, 132, 136, 140, 144, 149, 153, 157, 161, 165
];

export const GENERAL_5G_CHANNEL_LIST: Array<number> = [
    100, 104, 108, 112, 116, 120, 124, 128, 132, 136, 140, 144
];

export const AU_5G_CHANNEL_LIST: Array<number> = [
    100, 104, 108, 112, 116, 132, 136, 140, 144
];

export const OPENWRT_LTE_BAND = {
    [SupportDeviceModel.M_300AX]: [
        { name: 'B1', value: '1' },
        { name: 'B8', value: '8' },
        { name: 'B41', value: '41' },
        { name: 'B42', value: '42' }
    ],
    [SupportDeviceModel._8355P]: [
        { name: 'B2', value: '2' },
        { name: 'B4', value: '4' },
        { name: 'B5', value: '5' },
        { name: 'B7', value: '7' },
        { name: 'B12', value: '12' },
        { name: 'B13', value: '13' },
        { name: 'B14', value: '14' },
        { name: 'B17', value: '17' },
        { name: 'B25', value: '25' },
        { name: 'B26', value: '26' },
        { name: 'B29', value: '29' },
        { name: 'B30', value: '30' },
        { name: 'B38', value: '38' },
        { name: 'B41', value: '41' },
        { name: 'B42', value: '42' },
        { name: 'B43', value: '43' },
        { name: 'B46', value: '46' },
        { name: 'B48', value: '48' },
        { name: 'B66', value: '66' },
        { name: 'B70', value: '70' },
        { name: 'B71', value: '71' }],
    default: [
        { name: 'B2', value: '2' },
        { name: 'B4', value: '4' },
        { name: 'B5', value: '5' },
        { name: 'B7', value: '7' },
        { name: 'B12', value: '12' },
        { name: 'B13', value: '13' },
        { name: 'B14', value: '14' },
        { name: 'B17', value: '17' },
        { name: 'B25', value: '25' },
        { name: 'B26', value: '26' },
        { name: 'B29', value: '29' },
        { name: 'B30', value: '30' },
        { name: 'B38', value: '38' },
        { name: 'B41', value: '41' },
        { name: 'B42', value: '42' },
        { name: 'B43', value: '43' },
        { name: 'B46', value: '46' },
        { name: 'B48', value: '48' },
        { name: 'B66', value: '66' },
        { name: 'B70', value: '70' },
        { name: 'B71', value: '71' }]
};

export const OPENWRT_NR_BAND = {
    [SupportDeviceModel.M_300AX]: [
        { name: "n3", value: "3" },
        { name: "n28", value: "28" },
        { name: "n41", value: "41" },
        { name: "n77", value: "77" },
        { name: "n78", value: "78" },
        { name: "n79", value: "79" }
    ],
    [SupportDeviceModel._8355P]: [
        { name: "n2", value: "2" },
        { name: "n5", value: "5" },
        { name: "n7", value: "7" },
        { name: "n12", value: "12" },
        { name: "n13", value: "13" },
        { name: "n14", value: "14" },
        { name: "n25", value: "25" },
        { name: "n26", value: "26" },
        { name: "n29", value: "29" },
        { name: "n30", value: "30" },
        { name: "n38", value: "38" },
        { name: "n41", value: "41" },
        { name: "n48", value: "48" },
        { name: "n66", value: "66" },
        { name: "n70", value: "70" },
        { name: "n71", value: "71" },
        { name: "n77", value: "77" },
        { name: "n78", value: "78" }
    ],
    default: [
        { name: "n2", value: "2" },
        { name: "n5", value: "5" },
        { name: "n7", value: "7" },
        { name: "n12", value: "12" },
        { name: "n13", value: "13" },
        { name: "n14", value: "14" },
        { name: "n25", value: "25" },
        { name: "n26", value: "26" },
        { name: "n29", value: "29" },
        { name: "n30", value: "30" },
        { name: "n38", value: "38" },
        { name: "n41", value: "41" },
        { name: "n48", value: "48" },
        { name: "n66", value: "66" },
        { name: "n70", value: "70" },
        { name: "n71", value: "71" },
        { name: "n77", value: "77" },
        { name: "n78", value: "78" }
    ]
};

export const OPENWRT_NETWORK_MODE_OPTIONS: Array<SelectOption> = [
    { name: 'WCDMA only', value: '1' },
    { name: 'Automatic', value: '21' },
    { name: 'LTE only', value: '3' },
    { name: '5G Only (SA mode)', value: '15' },
    { name: 'LTE + 5G', value: '19' },
];

export const OPENWRT_ACS_DEVICE_DATA_MODEL_OPTIONS: Array<SelectOption> = [
    { name: 'TR098', value: '0' },
    { name: 'TR181', value: '1' }
];

export const OPENWRT_ACS_INTERFACE_OPTIONS: Array<SelectOption> = [
    { name: 'unspecified', value: '' },
    { name: 'wan', value: 'wan' }
];

export const OPENWRT_WIFI_ACCESS_POINT_OPTIONS: Array<SelectOption> = [
    { name: 'Enable', value: '0' },
    { name: 'Disable', value: '1' }
];

export const OPENWRT_WIFI_24G_WIRELESS_MODE_OPTIONS: Array<SelectOption> = [
    { name: '802.11 ax', value: '11ax' },
    { name: '802.11 n', value: '11n' },
    { name: '802.11 n+g', value: '11ng' },
    { name: '802.11 b+g', value: '11bg' }
];

export const OPENWRT_WIFI_5G_WIRELESS_MODE_OPTIONS: Array<SelectOption> = [
    { name: '802.11 ax', value: '11ax' },
    { name: '802.11 ac', value: '11ac' },
    { name: '802.11 n', value: '11n' },
    { name: '802.11 a', value: '11a' }
];

export const OPENWRT_WIFI_24G_BANDWIDTH_OPTIONS: Array<SelectOption> = [
    { name: '20 MHz', value: '[SHORT-GI-20]' },
    { name: '20/40 MHz', value: '[HT40-][HT40+][SHORT-GI-20][SHORT-GI-40]' }
];

export const OPENWRT_WIFI_5G_BANDWIDTH_OPTIONS: Array<SelectOption> = [
    { name: '20 MHz', value: '[SHORT-GI-20]' },
    { name: '40 MHz', value: '[HT40+][HT40-]' },
    { name: '80 MHz', value: '80' }
];

export const OPENWRT_WIFI_IGNORE_BROADCAST_SSID_OPTIONS: Array<SelectOption> = [
    { name: 'Disable', value: '1' },
    { name: 'Enable', value: '0' }
];

export const OPENWRT_WIFI_SECURITY_TYPE_OPTIONS: Array<SelectOption> = [
    { name: 'OPEN', value: 'OPEN' },
    { name: 'WEP 64-bit', value: 'WEP-64Bits' },
    { name: 'WEP 128-bit', value: 'WEP-128Bits' },
    { name: 'WPA-PSK', value: 'WPAPSK' },
    { name: 'WPA2-PSK', value: 'WPA2PSK' },
    { name: 'WPA3 SAE', value: 'WPA3SAE' },
    { name: 'Mixed WPA2/WPA3-PSK SAE', value: 'WPAPSKWPA2PSKSAE' },
];

export const OPENWRT_WIFI_WPA_PAIRWISE_OPTIONS: Array<SelectOption> = [
    { name: 'AES', value: 'CCMP' },
    { name: 'TKIP', value: 'TKIP' }
];