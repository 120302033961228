/******* Angular Resourse *******/
import { Component, OnInit, Input, Output, ViewChild, ElementRef, SimpleChanges, EventEmitter } from '@angular/core';

/******* Type Resourse *******/
import { OverlayMaps, MapIcons, Popup, OverlayMapsType, MapType, Coordinate } from '@core/types';

/******* Plug-In Resourse *******/
import * as L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.fullscreen';

/******* Environment Resourse *******/
import { environment } from 'environments/environment';

/******* Const Resourse *******/
const GEOAPIFY_API_KEY: string = 'd2981e8678e845fda45633d22373783d';
const GEOFENCE_ENABLE: boolean = false;
const CELL_CLUSTER_GROUP_OPTIONS = {
    iconCreateFunction: function (cluster) {
        const number = cluster.getChildCount();
        let className = "cluster";
        let iconSize;
        if (number < 10) {
            className += " cluster-m1";
            iconSize = L.point(53, 52);
        }
        else if (number < 100) {
            className += " cluster-m2";
            iconSize = L.point(56, 55);
        }
        else if (number < 500) {
            className += " cluster-m3";
            iconSize = L.point(66, 65);
        }
        else if (number < 1000) {
            className += " cluster-m4";
            iconSize = L.point(78, 77);
        }
        else {
            className += " cluster-m5";
            iconSize = L.point(90, 89);
        }

        return L.divIcon({
            html: number,
            className,
            iconSize
        });
    }
};

@Component({
    selector: 'geoapify-map',
    templateUrl: './core-map.component.html',
    styleUrls: ['core-map.component.scss']
})
export class MapComponent implements OnInit {

    /******* Element Resourse *******/

    // Input data

    // Location
    @Input() Latitude: number = 0.0;
    @Input() Longitude: number = 0.0;

    // Zoom
    @Input() Zoom: number = 13;

    // With Map Attribution
    @Input() withMapAttribution: boolean = false;

    // With Layers Switcher
    @Input() withLayerSwitcher: boolean = false;

    // With Marker Cluster
    @Input() withMarkerCluster: boolean = true;

    // With Cell Cluster
    @Input('withCellCluster') withCellCluster: boolean = true;

    // With Device
    @Input() withDeviceDisplay: boolean = false;

    // Drawing completed
    @Output('drawingResults') drawingResults: EventEmitter<Array<L.LatLng>> = new EventEmitter<Array<L.LatLng>>();

    // Map Container
    private lefletMap: L.Map;

    // Layer Control
    private layerControl: L.Control.Layers;

    // OverlayMaps
    private overlayMaps: Partial<OverlayMaps>;

    // Map Element
    @ViewChild('map') private mapContainer: ElementRef<HTMLElement>;

    // Map Type
    private readonly mapType: MapType = {
        "Bright": `https://maps.geoapify.com/v1/tile/osm-bright/{z}/{x}/{y}.png?apiKey=${GEOAPIFY_API_KEY}`,
        "Bright-Smooth": `https://maps.geoapify.com/v1/tile/osm-bright-smooth/{z}/{x}/{y}.png?apiKey=${GEOAPIFY_API_KEY}`,
        "Dark": `https://maps.geoapify.com/v1/tile/dark-matter-purple-roads/{z}/{x}/{y}.png?apiKey=${GEOAPIFY_API_KEY}`,
        "Liberty": `https://maps.geoapify.com/v1/tile/osm-liberty/{z}/{x}/{y}.png?apiKey=${GEOAPIFY_API_KEY}`
    };

    // Drawing Mode
    private _drawingMode: boolean = false;
    private _drawingPoints: Array<L.LatLng> = [];

    /**
     * constructor
     */
    constructor() { }

    //  Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter : access Map Zoom
     */
    public get mapZoom() {
        return this.lefletMap ? this.lefletMap.getZoom() : 0;
    }

    /**
     * Get the map instance
     */
    get map() {
        return this.lefletMap ? this.lefletMap : null;
    }

    /**
     * is map function available
     */
    get isMapFeatureAvailable() {
        return environment.internetFunction;
    }

    /**
     * is drawing mode
     */
    get isDrawingMode() {
        return this._drawingMode;
    }

    // Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    ngOnInit() {

        // initial Overlay Map
        this.overlayMaps = {
            [OverlayMapsType.MARKERS]: this.withMarkerCluster ? L.markerClusterGroup() : L.layerGroup(),
            [OverlayMapsType.CELL_MARKERS]: this.withCellCluster ? L.markerClusterGroup(CELL_CLUSTER_GROUP_OPTIONS) : L.layerGroup(),
            [OverlayMapsType.CELL_MARKERS_HISTORY]: this.withCellCluster ? L.markerClusterGroup(CELL_CLUSTER_GROUP_OPTIONS) : L.layerGroup(),
            [OverlayMapsType.KML_POLY]: L.layerGroup(),
            [OverlayMapsType.KML_CELL_MARKERS]: this.withCellCluster ? L.markerClusterGroup(CELL_CLUSTER_GROUP_OPTIONS) : L.layerGroup()
        };

        if (GEOFENCE_ENABLE) {
            this.overlayMaps[OverlayMapsType.DRAWING] = L.layerGroup();
            this.overlayMaps[OverlayMapsType.GEOFENCE] = L.layerGroup();
        }
    }

    /**
     * ngOnChanges
     * 
     * @param changes 
     */
    ngOnChanges(changes: SimpleChanges) {

        // detect change
        if (changes.Latitude || changes.Longitude || changes.Zoom) {
            if (this.lefletMap) this.setView(this.Latitude, this.Longitude, this.Zoom);
        }

    }

    /**
     * ngAfterViewInit
     */
    ngAfterViewInit() {

        if (environment.internetFunction) {

            // Create Base Layer Maps Option
            let baseMaps = this.renderBaseMaps();

            let overlayMapOptions = { // map layer option
                'CPE': this.overlayMaps[OverlayMapsType.MARKERS],
                'Cell-Site': this.overlayMaps[OverlayMapsType.CELL_MARKERS],
                'KML-Cell-Site': this.overlayMaps[OverlayMapsType.KML_CELL_MARKERS],
                'KML-Poly': this.overlayMaps[OverlayMapsType.KML_POLY]
            };
            if (GEOFENCE_ENABLE) overlayMapOptions['Geo-Fence'] = this.overlayMaps[OverlayMapsType.GEOFENCE];

            let layers = [
                baseMaps?.Liberty,
                this.overlayMaps[OverlayMapsType.MARKERS],
                this.overlayMaps[OverlayMapsType.CELL_MARKERS],
                this.overlayMaps[OverlayMapsType.CELL_MARKERS_HISTORY],
                this.overlayMaps[OverlayMapsType.KML_CELL_MARKERS],
                this.overlayMaps[OverlayMapsType.KML_POLY]
            ];
            if (GEOFENCE_ENABLE) layers.push(this.overlayMaps[OverlayMapsType.GEOFENCE])

            // Initial the Map
            this.lefletMap = L.map(this.mapContainer.nativeElement, {
                center: [this.Latitude, this.Longitude],
                zoom: this.Zoom,
                layers, // set Liberty as default base
                fullscreenControl: true,
                fullscreenControlOptions: {
                    position: 'topleft',
                    title: 'Full Screen',
                    titleCancel: 'Back',
                    forcePseudoFullscreen: true, // limit fullscreen to window of map
                }
            });

            // Create layer control
            this.layerControl = L.control.layers(baseMaps, overlayMapOptions, { hideSingleBase: true }).addTo(this.lefletMap);

            // Add Center Device Marker
            if (this.withDeviceDisplay) {
                this.clearLayerById(OverlayMapsType.MARKERS);
                this.addMarkerLayer("Device", MapIcons.DEVICE_GREY, this.Latitude, this.Longitude, OverlayMapsType.MARKERS);
            }

            // Remove Attribution
            if (!this.withMapAttribution) {
                let ele = document.getElementsByClassName('leaflet-control-attribution');

                for (let index = 0; index < ele.length; index++)
                    (<HTMLElement>ele[index]).style.display = 'none';
            }

            // Remove Layer Switcher
            if (!this.withLayerSwitcher) {
                let ele = document.getElementsByClassName('leaflet-control-layers');

                for (let index = 0; index < ele.length; index++)
                    (<HTMLElement>ele[index]).style.display = 'none';
            }

            // Full Screen enter and leave call back
            this.lefletMap.on('enterFullscreen', () => {
                this.fitBounds(50);
                this.closeAllPopup();
            });

            this.lefletMap.on('exitFullscreen', () => {
                this.fitBounds(50);
                this.closeAllPopup();
            });

            // Click Map
            if (GEOFENCE_ENABLE) {
                this.lefletMap.on('click', (event) => {
                    if (this._drawingMode) this._handleDrawing(event);
                });
            }
        }
    }

    // Private Methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * handle drawing event
     * 
     * @param event 
     */
    private _handleDrawing(event): void {
        switch (event.type) {
            case 'click':
                if (this._drawingPoints.length > 0)
                    this._drawLineInDrawing([this._drawingPoints[this._drawingPoints.length - 1], event.latlng]);
                this._drawPointInDrawing(event.latlng);
                break;
            default:
                break;
        };
    }

    /**
     * draw point in drawing 
     * 
     * @param {LatLng} latLng 
     */
    private _drawPointInDrawing(latLng: L.LatLng): void {
        const cutomizeIcon: L.IconOptions = {
            iconUrl: `assets/images/map/map_cursor.svg`,
            iconSize: [19, 19],
            popupAnchor: [0, 10],
            iconAnchor: [10, 10],
            tooltipAnchor: [10, 0]
        };
        let icon = L.icon(cutomizeIcon);
        let markerTemplate = L.marker([latLng.lat, latLng.lng], {
            icon: icon,
            zIndexOffset: 3,
            opacity: 0.6
        });
        markerTemplate.on('click', (event: Event) => {
            const initialPoint = this._drawingPoints[0];
            if (initialPoint.lat === latLng.lat && initialPoint.lng === latLng.lng && this._drawingPoints.length > 1) {
                // finish the geo fence
                const polygonPoints = [...this._drawingPoints];
                this.drawingResults.emit(polygonPoints); // emit results

                // clear drawing
                this._drawingPoints = [];
                this.clearLayerById(OverlayMapsType.DRAWING);
            }
        });
        markerTemplate.on('mouseover', function (e) {
            markerTemplate.setOpacity(1);
        });
        markerTemplate.on('mouseout', function (e) {
            markerTemplate.setOpacity(0.6);
        });
        markerTemplate.bindTooltip(`Point: ${latLng.lat}, ${latLng.lng}`);
        this.overlayMaps[OverlayMapsType.DRAWING].addLayer(markerTemplate);
        this._drawingPoints.push(latLng);
    }

    /**
     * draw line in drawing 
     * 
     * @param {Array<L.LatLng>} latLngs 
     */
    private _drawLineInDrawing(latLngs: Array<L.LatLng>): void {
        let polyLineTemplate = L.polyline(latLngs, {
            color: "#0377d0",
            weight: 3,
            opacity: 1
        });
        this.overlayMaps[OverlayMapsType.DRAWING].addLayer(polyLineTemplate);
    }

    /**
     * draw polygon in drawing
     * 
     * @param {Array<L.LatLng>} latLngs 
     * @param {string} name
     * @param {string} id
     */
    private _drawPolygonInGeoFence(latLngs: Array<L.LatLng>, name: string): any {
        let polygonTemplate = L.polygon(latLngs, {
            color: "#0377d0",
            weight: 1,
            opacity: 1,
            fillColor: "#0377d0",
            fillOpacity: 0.2
        });
        if (name) polygonTemplate.bindTooltip(name);
        this.overlayMaps[OverlayMapsType.GEOFENCE].addLayer(polygonTemplate);
        return polygonTemplate;
    }

    /**
     * Render Base Layer Map
     * 
     * @returns 
     */
    private renderBaseMaps(): L.Control.LayersObject {
        let baseMaps = {};
        for (let key in this.mapType) {
            baseMaps[key] = L.tileLayer(this.mapType[key], {
                maxZoom: 20,
                id: key + "_id",
                attribution: '© OpenStreetMap'
            });
        }
        return baseMaps;
    }

    /**
     * Get build in maps marker
     * 
     * @param {string} markerName
     */
    private buildInMapsMarker(markerName: string): L.IconOptions {
        return {
            iconUrl: `assets/images/map/${markerName}.png`,
            iconSize: [48, 48],
            iconAnchor: [24, 45],
            tooltipAnchor: [18, -24],
            popupAnchor: [0, -45]
        };
    }

    // Public Methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * add Marker Layer into Marker Group
     * 
     * @param {string} id 
     * @param {MapIcons | string} iconType 
     * @param {number} latitude 
     * @param {number} longitude 
     * @param {string} tootip 
     * @param {Popup} popup 
     */
    public addMarkerLayer(id: string,
        iconType: MapIcons | string,
        latitude: number,
        longitude: number,
        layer: OverlayMapsType,
        tootip: string = "",
        popup: Popup = {
            content: "",
            options: {}
        }): void {

        if (!environment.internetFunction) return;

        // Customize Icon
        let icon;
        iconType = iconType ? iconType : MapIcons.CELL;
        if (!Object.keys(MapIcons).map(x => MapIcons[x]).includes(iconType)) {
            const cutomizeIcon: L.IconOptions = {
                iconUrl: iconType,
                iconSize: [30, 30],
                popupAnchor: [0, -7.5],
                iconAnchor: [15, 15],
                tooltipAnchor: [15, 0]
            };
            icon = L.icon(cutomizeIcon);
        } else icon = L.icon(this.buildInMapsMarker(iconType));

        // Validate Coordinate

        let markerTemplate = L.marker([latitude, longitude], {
            icon: icon,
            zIndexOffset: 3,
            title: id
        });

        if (tootip) markerTemplate.bindTooltip(tootip);
        if (popup.content) markerTemplate.bindPopup(popup.content, popup.options);

        // Add to Marker Layer
        this.overlayMaps[layer].addLayer(markerTemplate);
        // if (iconType === MapIcons.DEVICE || iconType === MapIcons.DEVICE_GREY) this.overlayMaps.markers.addLayer(markerTemplate);
        // else this.overlayMaps.cellMarkers.addLayer(markerTemplate);
    }

    /**
     * Drawing Line
     * 
     * @param polyline_ [[Latitude,Longtitude],[Latitude,Longtitude]]
     * @param tootip 
     * @param popup
     * @returns 
     */
    public drawLine(polyline_: any,
        tootip: string = "",
        popup: string = "",
        options: any = {
            color: "#ea5455",
            weight: 2,
            opacity: 0.7
        }): boolean | void {

        if (!environment.internetFunction) return;

        let polyLineTemplate = L.polyline(polyline_, {
            ...options
        });

        if (tootip) polyLineTemplate.bindTooltip(tootip);
        if (popup) polyLineTemplate.bindPopup(popup);

        // Add to Marker Layer
        this.overlayMaps[OverlayMapsType.KML_POLY].addLayer(polyLineTemplate);
    }

    /**
     * Drawing Polygon
     * 
     * @param {Array<L.Latlng>} polygon_ 
     * @param {string} tootip 
     * @param {string} popup 
     * @param {string} color 
     * @param {number} weight 
     * @param {number} opacity 
     * @param {string} fillColor 
     * @param {number} fillOpacity 
     * @returns {boolean | void}
     */
    public drawPolygon(polygon_: Array<L.Latlng>,
        tootip: string = "",
        popup: string = "",
        color: string = "#ea5455",
        weight: number = 2,
        opacity: number = 0.7,
        fillColor: string = "#7367f0",
        fillOpacity: number = 0.2): boolean | void {

        if (!environment.internetFunction) return;

        let polygonTemplate = L.polygon(polygon_, {
            color: color ? color : "#ea5455",
            weight: weight ? weight : 2,
            opacity: opacity ? opacity : 0.7,
            fillColor: fillColor ? fillColor : "#7367f0",
            fillOpacity: fillOpacity ? fillOpacity : 0.2
        });

        if (tootip) polygonTemplate.bindTooltip(tootip);
        if (popup) polygonTemplate.bindPopup(popup);

        // Add to Marker Layer
        this.overlayMaps[OverlayMapsType.KML_POLY].addLayer(polygonTemplate);
    }

    /**
     * Drawing circle
     * 
     * @param {L.Latlng} latLng 
     * @param {number} radius
     * @param {any} options // #circle-option
     * @returns {boolean | void}
     */
    public drawCircle(latLng: L.Latlng,
        radius: number,
        options?: any): boolean | void {
        if (!environment.internetFunction) return;
        let circleTemplate = L.circle(latLng, radius, options);
        this.overlayMaps[OverlayMapsType.KML_POLY].addLayer(circleTemplate); // Add to Marker Layer
    }

    /**
     * Update Popup content by id
     * 
     * @param {string} id 
     * @param {string} updateContent 
     */
    public updatePopupFromMarker(id: string, updateContent: string, fromOverlay: OverlayMapsType = OverlayMapsType.MARKERS): void {
        if (!environment.internetFunction) return;
        this.overlayMaps[fromOverlay].eachLayer((layer: L.Marker) => {
            if (layer.options.title === id) {
                layer.setPopupContent(updateContent);
            };
        });
    }

    /**
     * Fit Bounds
     * 
     * @param {number} paddingValue
     */
    public fitBounds(paddingValue: number): void {

        if (!environment.internetFunction) return;

        // Bounds Aarray
        let boundArray: Array<L.LatLng> = [];

        // Marker Bounds
        this.overlayMaps[OverlayMapsType.MARKERS].eachLayer((layer: L.Marker) => {
            boundArray.push(layer.getLatLng());
        });

        this.overlayMaps[OverlayMapsType.CELL_MARKERS].eachLayer((layer: L.Marker) => {
            boundArray.push(layer.getLatLng());
        });

        this.overlayMaps[OverlayMapsType.KML_CELL_MARKERS].eachLayer((layer: L.Marker) => {
            boundArray.push(layer.getLatLng());
        });

        // Calculate Bounds
        let bounds = L.latLngBounds(boundArray);
        let polyFlag = false;

        // Polyline Bounds
        this.overlayMaps[OverlayMapsType.KML_POLY].eachLayer((layer: L.Polyline) => {
            bounds.extend(layer.getBounds());
            polyFlag = true;
        });

        // Geo Fence Bounds
        if (GEOFENCE_ENABLE) {
            this.overlayMaps[OverlayMapsType.GEOFENCE].eachLayer((layer: L.Polygon) => {
                bounds.extend(layer.getBounds());
            });
        }

        if (boundArray.length > 0)
            this.lefletMap.fitBounds(bounds, { padding: [paddingValue + 20, paddingValue + 20] }); // Fit Bounds with padding

        if (!polyFlag && boundArray.length == 1) this.lefletMap.setView(boundArray[0], 14);
    }

    /**
     * Fit Single Bounds
     * 
     * @param {Array<L.LatLng>} latLngs
     * @param {number} paddingValue
     */
    public fitSingleBounds(latLngs: Array<L.LatLng>, paddingValue: number): void {
        if (latLngs.length === 0) return;
        this.lefletMap.fitBounds(L.latLngBounds(latLngs), { padding: [paddingValue + 20, paddingValue + 20] });
    }

    /**
     * Clear the Map Object
     */
    public clearMap(): void {
        if (!environment.internetFunction) return;
        // Clear Marker Layer
        for (let laygroup in this.overlayMaps) {
            this.overlayMaps[laygroup].clearLayers();
        }
    }

    /**
     * Move the Map View
     * 
     * @param lat 
     * @param long 
     * @param zoom 
     * @returns 
     */
    public setView(lat, long, zoom): boolean | void {
        if (!environment.internetFunction) return;
        if (lat > 90.0 || lat < -90.0 || long > 180.0 || long < -180.0 || zoom > 20 || zoom < 1) {
            console.log("SetView with incorrect Value");
            return false;
        }
        this.lefletMap.setView([lat, long], zoom);
    }

    /**
     * Resize the Map
     */
    public invalidateSize(): void {
        if (!environment.internetFunction) return;
        this.lefletMap.invalidateSize();
    }

    /**
     * Clear assigned layed
     * 
     * @param {string} id
     */
    public clearLayerById(id): void {
        if (!environment.internetFunction) return;
        this.overlayMaps[id].clearLayers();
    }

    /**
     * Close all popup in marker
     */
    public closeAllPopup(): void {
        if (!environment.internetFunction) return;

        this.overlayMaps.markers.eachLayer((layer: L.Marker) => {
            layer.closePopup();
        });

        this.overlayMaps.cellMarkers.eachLayer((layer: L.Marker) => {
            layer.closePopup();
        });
    }

    /**
     * Toggle drawing mode
     */
    public toggleDrawingMode(): void {
        this._drawingMode = !this._drawingMode;

        // marker layer
        const markerLayers: Array<OverlayMapsType> = [
            OverlayMapsType.MARKERS,
            OverlayMapsType.CELL_MARKERS,
            OverlayMapsType.CELL_MARKERS_HISTORY,
            OverlayMapsType.KML_CELL_MARKERS,
            OverlayMapsType.KML_POLY
        ];
        if (this._drawingMode) {
            this._drawingPoints = []; // initial point set
            this.clearLayerById(OverlayMapsType.DRAWING);
            markerLayers.forEach((layerKey) => {
                if (this.lefletMap.hasLayer(this.overlayMaps[layerKey]))
                    this.lefletMap.removeLayer(this.overlayMaps[layerKey]);
            });
            this.lefletMap.addLayer(this.overlayMaps[OverlayMapsType.DRAWING]);
            if (!this.lefletMap.hasLayer(this.overlayMaps[OverlayMapsType.GEOFENCE]))
                this.lefletMap.addLayer(this.overlayMaps[OverlayMapsType.GEOFENCE]);
        }
        else {
            markerLayers.forEach((layerKey) => {
                if (!this.lefletMap.hasLayer(this.overlayMaps[layerKey]))
                    this.lefletMap.addLayer(this.overlayMaps[layerKey]);
            });
            this.lefletMap.removeLayer(this.overlayMaps[OverlayMapsType.DRAWING]);
        }

        // Layer control
        let ele = document.getElementsByClassName('leaflet-control-layers');
        for (let index = 0; index < ele.length; index++)
            (<HTMLElement>ele[index]).style.visibility = this._drawingMode ? 'hidden' : 'visible';

    }

    /**
     * Adding Geo Fence
     * 
     * @param {string} name 
     * @param {Array<L.LatLng>} latLng 
     */
    public addGeoFence(name: string, latLng: Array<L.LatLng>): any {
        return this._drawPolygonInGeoFence(latLng, name);
    }

    /**
     * Toggle Visibility on layer
     * 
     * @param template 
     * @param {OverlayMapsType} type 
     */
    public toggleVisibility(template: any, type: OverlayMapsType): void {
        if (this.overlayMaps[type].hasLayer(template)) this.overlayMaps[type].removeLayer(template);
        else this.overlayMaps[type].addLayer(template);
    }

    /**
     * Revmoe layer
     * 
     * @param template 
     * @param {OverlayMapsType} type 
     */
    public removeLayer(template: any, type: OverlayMapsType): void {
        if (this.overlayMaps[type].hasLayer(template))
            this.overlayMaps[type].removeLayer(template);
    }
}
