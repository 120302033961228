import { TimeZone } from "../time-zone-types";
import { RecursiveTermType, TaskTypeValue } from "./task-type";

export interface Periods {
    startDate: string; // yyyy-mm-dd
    endDate: string; // yyyy-mm-dd
    startTime: string; // hh:mm:00
    endTime: string; // hh:mm:00
};

export interface SchedulerRequestBody {
    id?: string;
    name: string;
    type: TaskTypeValue;
    description: string;
    model?: string;
    fw?: string;
    pci?: string;
    enbId?: string;
    fwFile?: string;
    radioType?: Array<string>;
    groupIds?: Array<string>;
    speedTestUrl?: string;
    latencyTestUrl?: string;
    pingInterval?: number;
    timezone: TimeZone;
    startAt?: string;
    timeWindow?: boolean;
    endAt?: string;
    failedRetry?: boolean;
    retryDuration?: number;
    emailLoop?: string;
    periods?: Array<Periods>;
    speedTestStart?: Array<string>;
    speedTestEnd?: Array<string>;
    onDemandMode?: boolean;
    profileTemplateId?: string;
    reportId?: string;
    reportUsageRange?: string;
    recursiveTerm?: RecursiveTermType;
    recursiveTime?: string;
    recursivePeriods?: string;
    recursiveDayInMonth?: string;

};

export enum FilterComparison {
    LESS_THAN = "Lt",
    EQUAL = "Eq",
    LESS_THAN_EQUAL = "Lte",
    GREATER_THAN = "Gt",
    GREATER_THAN_EQUAL = "Gte",
    DURING_THE_DATE = "On",
    IN_RANGE_DATE = "DateRange",
    SEARCH = "Search",
    LIKE = "Like",
};

export interface ScheduleFilter {
    k: string;
    c: FilterComparison;
    v: any
};

export enum SortingDirection {
    ASC = "asc",
    DESC = "desc"
};

export interface SortingCondition {
    k: string;
    d: SortingDirection;
};

export interface ScheduleFilters {
    pageIndex: number; // start from 1
    pageSize: number; // page size
    filters?: Array<ScheduleFilter>;
    sorts?: Array<SortingCondition>;
};

export interface ScheduleActionRequestBody {
    scheduleId: string;
};