import { Month, RecursiveTermType, SelectOption, TaskTypeValue, WeekDay } from "@core/types";

export const TASK_TYPE_LIST: Array<SelectOption> = [
    { name: "Reboot", value: TaskTypeValue.REBOOT },
    /* { name: "Reset to Default", value: "reset" }, */
    { name: "Upgrade Firmware with Current Setting", value: TaskTypeValue.UPGRADE_FIRMWARE_WITH_CURRENT_SETTING },
    { name: "Upgrade Firmware with Factory Default Setting", value: TaskTypeValue.UPGRADE_FIRMWARE_WITH_FACTORY_DEFAULT_SETTING },
    /* { name: "Upgrade Configuration", value: "updateConfig" },
    { name: "Group", value: "group" },
    { name: "Auto Configuration Backup", value: "autoCfgBackup" },
    { name: "Recursive Report to Email", value: "recursiveReport" },
    { name: "FCC Speed Test (A-CAM & CAF)", value: "FCCTests" }, */
    { name: "Speed Test to Email", value: TaskTypeValue.SPEED_TEST_TO_EMAIL },
    { name: "Latency Test to Email", value: TaskTypeValue.LATENCY_TEST_TO_EMAIL },
    { name: "Profile Implement", value: TaskTypeValue.PROFILE_IMPLEMENT }
];

export const RECURSIVE_TASK_TYPE_LIST: Array<SelectOption> = [
    { name: "Recursive Report to Email", value: TaskTypeValue.RECURSIVE_REPORT_TO_EMAIL }
];

export const FAILED_RETRY_INTERVAL_LIST: Array<SelectOption> = [
    { name: "15 minutes", value: '900' },
    { name: "20 minutes", value: '1200' },
    { name: "25 minutes", value: '1500' },
    { name: "30 minutes", value: '1800' },
    { name: "35 minutes", value: '2100' },
    { name: "40 minutes", value: '2400' },
    { name: "45 minutes", value: '2700' },
    { name: "50 minutes", value: '3000' },
    { name: "55 minutes", value: '3300' },
    { name: "60 minutes", value: '3600' }
];

export const RECURSIVE_TERM_LIST: Array<SelectOption> = [
    { name: "Daily", value: RecursiveTermType.DAILY },
    { name: "Weekly", value: RecursiveTermType.WEEKLY },
    { name: "Monthly", value: RecursiveTermType.MONTHLY }
];

export const WEEK_LIST = [
    { name: "Monday", value: WeekDay.MONDAY },
    { name: "Tuesday", value: WeekDay.TUESDAY },
    { name: "Wednesday", value: WeekDay.WEDNESDAY },
    { name: "Thursday", value: WeekDay.THURSDAY },
    { name: "Friday", value: WeekDay.FRIDAY },
    { name: "Saturday", value: WeekDay.SATURADAY },
    { name: "Sunday", value: WeekDay.SUNDAY }
];

export const MONTH_LIST = [
    { name: 'January', value: Month.JANUARY },
    { name: 'February', value: Month.FEBRUARY },
    { name: 'March', value: Month.MARCH },
    { name: 'April', value: Month.APRIL },
    { name: 'May', value: Month.MAY },
    { name: 'June', value: Month.JUNE },
    { name: 'July', value: Month.JULY },
    { name: 'August', value: Month.AUGUST },
    { name: 'September', value: Month.SEPTEMBER },
    { name: 'October', value: Month.OCTOBER },
    { name: 'November', value: Month.NOVEMBER },
    { name: 'December', value: Month.DECEMBER }
];