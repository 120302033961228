/** Adding Role Permission
 * 1. Adding enum RolePermission
 * 2. Adding PERMISSION constant
 * 3. adding parsing item in function parsingPermissionGroup
 */
export enum RolePermission {
    COMMAND_REBOOT = 'CommandReboot',
    COMMAND_RESTORE = 'CommandRestore',
    COMMAND_UPGRADE_FW = 'CommandUpgradeFw',
    COMMAND_UPGRADE_CFG = 'CommandUpgradeCfg',
    COMMAND_BACKUP_CFG = 'CommandBackupCfg',
    COMMAND_DIAGNOSTIC = 'CommandDiagnostic',
    COMMAND_CBSD = 'CommandCBSD',
    DEVICE_CFG = 'DeviceCfg',
    DEVICE_ANALYSIS = 'DeviceAnalysis',
    DEVICE_EDIT = 'DeviceEditInfo',
    DEVICE_REMOTE_ACCESS = 'DeviceRemoteAccess',
    DEVICE_DELETE = 'DeleteDevice',
    VIEW_ANALYTICS = 'ViewAnalytics',
    VIEW_CELL_ANALYSIS = 'ViewCellAnalysis',
    VIEW_SCHEDULER = 'ViewScheduler',
    VIEW_FILE_MANAGEMENT = 'ViewFileManagement',
    VIEW_REPORT = 'ViewReport',
    VIEW_MAP = 'ViewMap',
    VIEW_PROFILE_TEMPLATE = 'ViewProfileTemplate',
    FEATURES_ALERT = 'FeatureAlert',
    FEATURES_WEBHOOK = 'FeatureWebhook',
    FEATURES_REST_API = 'FeatureRestApi',
    CONFIG_INTERFACE = 'ConfigInterface',
    CONFIG_DUAL_WAN = 'ConfigDualWan',
    CONFIG_WIFI = 'ConfigWifi',
    CONFIG_ACCESS_MANAGEMENT = 'ConfigAccessManagement',
    CONFIG_MAINTENANCE = 'ConfigMaintenance',
    CONFIG_ADVANCED_SETUP = 'ConfigAdvancedSetup',
    ADMIN_ACCESS= 'AdministatorAccess',
    SUPER_ADMIN_ACCESS= 'SuperAdminAccess'
};

export interface PermissionItem {
    id: string;
    name: string;
    children?: Array<PermissionItem>;
};