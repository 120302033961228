export interface SignalStandard {
    excellent: number;
    good: number;
    midcell: number;
    celledge: number;
};

export interface Signal {
    RSRP: SignalStandard;
    RSRQ: SignalStandard;
    RSSI: SignalStandard;
    SINR: SignalStandard;
};

export enum CellType {
    'ECI' = 'ECI',
    'NCI' = 'NCI'
};