export enum ConfigurationFunction {
    // INTERNET
    INTERNET_LTE = "internet_lte",
    INTERNET_5G_NR = "internet_5g_nr",
    BECENTRAL = "becentral",
    CBSD = "cbsd",
    SERIAL_1 = "serial_1",
    SERIAL_2 = "serial_2",
    RGOOSE = "rgoose",
    PTP = "ptp",
    DEVICE_MANAGMENT = "device_management",
    ACCESS_CONTROL = "access_control",
    USER_MANAGEMENT = "user_management"
};

export enum ConfigurationNode {
    WAN = 'Wan',
    USAGE = 'Usage',
    MQTT_CONF = 'MqttConf',
    CBSD = 'CBSD',
    SERIAL_1 = 'SerialAPP1',
    SERIAL_2 = 'SerialAPP2',
    RGOOSE = 'Rgoose',
    PTP = 'PTP',
    LAN = 'Lan',
    WEB_SERVER = 'Webserver',
    ACCESS_CONTROL = "ACL",
    USER_MANAGEMENT = 'Account'
};

export type ConfigurationFunctionNameMap = {
    [key in ConfigurationFunction]: string;
};

export interface FunctionOption {
    name: string;
    value: string;
};

export interface ConfigurationFunctionProfile {
    configFunction: ConfigurationFunction;
    profile: FunctionProfile;
};

export interface FunctionProfile {
    [ConfigurationNode.WAN]?: any;
    [ConfigurationNode.USAGE]?: any;
    [ConfigurationNode.MQTT_CONF]?: any;
    [ConfigurationNode.CBSD]?: any;
    [ConfigurationNode.SERIAL_1]?: any;
    [ConfigurationNode.SERIAL_2]?: any;
    [ConfigurationNode.PTP]?: any;
    [ConfigurationNode.RGOOSE]?: any;
    [ConfigurationNode.LAN]?: any;
    [ConfigurationNode.WEB_SERVER]?: any;
    [ConfigurationNode.ACCESS_CONTROL]?: any;
};
