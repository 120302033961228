export interface DevcieTableDefination {
    key: string;
    name: string;
    sortable: boolean;
    colvis?: boolean;
    defaultDisplay: boolean;
    filterable: boolean;
    filterType: ColumnType;
    custom?: boolean;
    width: number;
    class: string;
    placeholder?: string;
    searchable: boolean;
};

export enum ColumnType {
    DEVICE = 'device',
    HARDWARE = 'hardware',
    NETWORK = 'network',
    SIGNAL = 'signal'
};